body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-flow__node-selectorNode {
  font-size: 1rem;
  background: #efeeff;
  border: 2px solid #0000001c;
  border-radius: 5px;
  text-align: center;
  padding: 0.3rem;
}


.react-flow__node-selectorNode.selected {
  border-color: #f05009;
}

.react-flow__panel {
  margin: 0 !important;
}

.arm input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.arm input[type=number] {
  -moz-appearance: textfield;
}


/* flow */

.nav-flows-cont {
  max-width: 100vw;
  padding: 0.3rem;
}

.nav-flows {
  overflow-x: hidden;
}

.flow-btn,
.nav-flows .nav-item {
  cursor: pointer;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
}

.flow-btn:hover,
.nav-flows .nav-item:hover {
  background-color: #ddd;
}

.nav-flows .nav-item:hover svg {
  color: #c5c5c5;
}

.nav-flows .nav-item {
  min-width: max-content;
  position: relative;
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
  height: 2.5rem;
}

.nav-flows .nav-item:last-child {
  margin: 0;
}

.nav-flows .nav-item svg {
  color: #ddd
}

.nav-flows .nav-item.active,
.nav-flows .nav-item.active svg {
  background-color: rgb(var(--bs-danger-rgb));
  border-color: rgb(var(--bs-danger-rgb));
  color: white;
}

.flow-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 2.5rem;
  min-width: 2.5rem;
}

#on-printer * {
  color: black !important;
  background-color: transparent !important;
}


.td-align-top [role="cell"] {
  align-items: start;
  padding: 0.5rem;
}

.blocked-prod-list li::marker,
.added-prod-list li::marker {
  font-weight: 600;
  font-size: 1rem;
}

.blocked-prod-list li::marker {
  content: '− ';
  color: red;
}

.added-prod-list li::marker {
  content: '+ ';
  color: green;
}

.tickets td {
  cursor:pointer;
}
.tickets td:last-child {
  cursor: default;
}